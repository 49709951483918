@import '../colors.scss';

#calendar {
    min-height: 825px;
    .title {
        max-width: 1280px;
        width: 100%;
        margin: auto !important;
    }
    .tab {
        margin: auto;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 2;
    }
    ul {
        list-style: none;
        display: flex;
        padding: 0;
        li {
            margin: 10px;
        }
    }
    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $main-color;
        h1 {
            color: $fifth-color;
            margin-top: 0;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        justify-items: center;
        max-width: 500px;
        margin-right: auto;
        margin-left: 40px;
        width: 100%;
        label {
            display: flex;
            flex-direction: column;
            &.require > span:after {
                content: '*';
                position: absolute;
                right: -10px;
            }
            &.require > span {
                color: $fifth-color;
                margin-bottom: 10px;
                margin-top: 30px;
                position: relative;
                width: fit-content;
            }
            input {
                padding: 20px 10px;
                background-color: $third-color;
                border: 0;
                font-size: 18px;
                color: $fifth-color;
                box-shadow: -5px 3px 10px -10px black;
            }
        }
    }
    .calendar {
        height: auto;
        min-height: 630px;
        width: 100%;
        h1 {
            margin-bottom: 0;
        }
        > p {
            max-width: 1280px;
            width: 100%;
            margin: 15px auto !important;
            color: $main-light-color;
            margin-top: 0;
            padding-left: 5px;
            > a {
                color: $main-light-color;
            }
            &:before {
                content: '';
                display: inline-block;
                height: 1px;
                width: 20px;
                background-color: $main-light-color;
                vertical-align: middle;
                margin-right: 15px;
            }
        }
    }
}
#slogan {
    display: flex;
    justify-content: center;
    align-items: center;
    > .title, h3 {
        color: white;
        position: absolute;
        z-index: 5;
    }
    > .title {
        top: 80px;
    }
    > .caption {
        top: 0;
        text-align: center;
    }
    > h2 {
        top: 50px;
        font-size: 3rem;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    > button {
        position: absolute;
        z-index: 5;
        top: 120px;
        color: white;
        border: 0;
        display: flex;
        align-items: center;
        opacity: 1;
        font-size: 14px !important;
        letter-spacing: 1px;
        outline: none !important;
        > svg {
            font-size: 26px !important;
        }
        &:hover {
            opacity: 0.8;
        }
    }
    .container {
        display: block;
        position: relative;
        width: 100vw;
        min-height: 100vh;
        &:before {
            content:'';
            display: block;
            position: relative;
            background: linear-gradient(0deg, #446c86 0%, #2a4d64 35%, #183243 100%);
            top: 0;
            left: 0;
            width: 100vw;
            height: 41.7vh;
        }
        &:after {
            content:'';
            display: block;
            position: relative;
            background: linear-gradient(0deg, #162b3a 17%, #214760fa 76%, #1b3c52f1 91%, #214760d5 100%);
            top: 0;
            left: 0;
            width: 100vw;
            height: 59.3vh;
            z-index: 3;
        }
        #ice {
            top: 19.9vh;
            left: 50%;
            transform: translateX(-50%);
            z-index: 4;
            width: 100%;
            height: 75vh;
            display: block;
            position: absolute;
            > .mountain {
                left: 50%;
                transform: translateX(-50%);
                width: auto;
                height: 100%;
            }
            > .logo {
                left: 50%;
                z-index: 5;
                transform: translateX(-50%);
                max-width: 8vh;
                top: 10vh;
                filter: brightness(0.3);
                mix-blend-mode: color-burn;
            }
            > h3 {
                left: 50%;
                z-index: 5;
                transform: translateX(-50%);
                top: 16.5vh;
                filter: brightness(0.4);
                color: $secondary-color;
                mix-blend-mode: color-burn;
                position: absolute;
                width: 40vh;
                font-size: 2vh;
                text-align: center;
                display: block;
                text-transform: uppercase;
            }
            > ul {
                left: 50%;
                transform: translateX(calc(-50% - 20px));
                z-index: 5;
                color: white;
                margin: 0;
                padding: 0;
                list-style: none;
                position: absolute;
                bottom: 13vh;
                li {
                    letter-spacing: 1px;
                    text-align: center;
                    font-size: 1.6vh;
                    margin: 2vh 0;
                }
            }
        }
        img {
            display: block;
            position: absolute;
            z-index: 2;
            width: 320px;

            &#boat {
                top: 27vh;
                left: calc(25% + 360px);
                width: auto;
                height: 15vh;
                z-index: 5;
            }
            &.cloud {
                z-index: 1;
            }
            &.bubble,
            &.bubble-2,
            &.bubble-3 {
                z-index: 5;
                bottom: 25px;
                width: 10px;
                left: 25%;
                opacity: 0;
            }
        }
    }
}
@import '../colors.scss';

#about, #why {
    background-color: $extra-color;
    padding-bottom: 25px;
    &:not(#about):not(#branches) {
        background-color: $extra-color-2;
        padding-top: 25px;
        padding-bottom: 50px;
        min-height: initial;
        position: relative;
        > .panels {
            width: 100vw;
        }
        > h3 {
            margin-right: auto;
            margin-left: 25px;
            letter-spacing: 2px;
        }
    }
    h2 {
        > img {
            left: 25px;
            bottom: 25px;
            width: 40px;
        }
    }
    .panels .panel {
        max-width: 1280px;
        .panel-details {
            box-sizing: border-box;
            width: 50%;
            > h2 {
                align-items: flex-start;
                > img {
                    margin: 0;
                    top: -5px;
                    position: relative;
                    left: 0;
                }
                > span {
                    margin-left: 15px;
                    width: calc(100% - 60px);
                    > img {
                        height: 30px;
                        position: relative;
                        top: 7px;
                        margin-right: 15px;
                    }
                }
            }
        }
        > img {
            width: 48% !important;
            height: 48% !important;
        }
        ul {
            padding-bottom: 25px;
            border-bottom: 1px solid white;
            li {
                display: flex;
                > svg {
                    top: 2px;
                    position: relative;
                    font-size: 30px;
                }
            }
        }
    }
    .title {
        color: $fifth-color;
        text-align: left;
        margin-right: auto;
        font-weight: 300;
        margin-bottom: 25px;
        padding-left: 25px;
    }
    p {
        line-height: 1.5;
    }
    .questions {
        h2,
        h4 {
            text-align: center;
            color: $fifth-color;
        }
        h2 {
            font-size: 4rem;
        }
        h4 {
            font-size: 2rem;
            font-weight: 300;
        }
    }
    ul {
        list-style: none;
        li {
            font-size: 1.5rem;
            margin: 15px 5px;
            display: flex;
            align-items: flex-start;
            img {
                margin-top: 5px;
            }
        }
    }
    .panel > img {
        border: 0;
    }
}
#why {
    > h3 {
        max-width: 1280px;
        display: block;
        margin: 50px auto !important;
        width: 100%;
        padding-left: 25px;
    }
    .panel-details {
        width: 100% !important;
    }
}
#services {
    background-color: #1F3336;
    color: $font-color-secondary;
    padding-top: 125px;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px 125px;
        width: 100%;
        max-width: 1455px;
        gap: 20px;
        box-sizing: border-box;

        .text {
            width: 57%;
            max-width: 790px;

            .title {
                color: $font-color-secondary;
                text-transform: uppercase;
                font-weight: 700;
                margin: 0 0 24px !important;
                font-size: 50px;
                line-height: 150%;
            }

            .subtitle {
                font-size: 18px;
                margin: 0 0 60px;
                line-height: 150%;
            }

            .btn-grouping {
                gap: 24px;
                margin: 0 0 24px;

                .btn {
                    margin-top: 0;
                    text-decoration: none;
                }

                .btn-primary, .btn-secondary {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .button-icon {
                        margin-left: 10px;
                    }
                }
            }

            .note {
                font-size: 14px;
                margin: 24px 0 0;

                a {
                    color: $font-color-secondary;
                    opacity: 1;
                    transition: all 150ms ease-in-out;
                }

                a:hover {
                    opacity: 0.7;
                }
            }
        }

        .image {
            width: 35%;
            max-width: 483px;
            height: auto;
        }
    }
}
@import '../colors.scss';

#hero {
    display: flex;
    justify-content: center;
    background-color: $main-color;
    > h2 {
        text-transform: uppercase;
    }
    .bg {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100%;
        display: block;
        overflow: hidden;
        opacity: 0.2;
        pointer-events: none;
        & > video {
            display: block;
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
        }
    }

    .socials {
        position: absolute;
        bottom: 25px;
        right: 25px;
        svg {
            fill: $secondary-color;
            font-size: 2rem;
            transition: all 0.5s ease;
            margin: 0 5px;
            cursor: pointer;
            transform: scale(1);
            &:hover {
                transform: scale(1.3);
                fill: darken($color: $secondary-color, $amount: 10%);
            }
        }
    }
    .g {
        position: absolute;
        bottom: 25px;
        left: 25px;
    }
    #main-logo {
        max-width: 280px;
        position: relative;
        z-index: 1;
        #logo {
            opacity: 1;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    h2 {
        color: $fourth-color;
        font-weight: normal;
        margin-top: 50px;
        position: relative;
        z-index: 1;
        font-size: 3rem;
    }
    h3 {
        font-weight: 300 !important;
        max-width: 768px;
        color: $fifth-color;
        text-align: center;
        position: relative;
        z-index: 2;
        font-size: 24px;
        letter-spacing: 1px;
    }
    .next-section {
        background-color: $secondary-color;
        fill: $main-color;
        position: relative;
        z-index: 1;
    }
    .btn-grouping {
        position: relative;
        z-index: 1;
    }
}
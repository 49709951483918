@import '../colors.scss';

#navigation {
    display: flex;
    padding: 25px;
    position: fixed;
    align-items: center;
    z-index: 999;
    width: calc(100vw - 50px);
    transition: all 0.5s ease;
    &.has-background {
        background-color: $main-color;
    }
    #name {
        margin: 10px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 5px;
        color: $main-light-color;
        display: flex;
        align-items: center;
        text-decoration: none;
        img {
            margin-left: 10px;
            width: 30px;
        }
    }
    nav {
        flex: auto;
    }
    nav ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
        align-items: center;

        li > a {
            margin-left: 0 !important;
        }
        li.btn {
            margin-top: 0;
        }
        li:not(.btn),
        li > a {
            margin: 10px 25px;
            color: $main-light-color;
            font-weight: 300;
            cursor: pointer;
            transition: all 0.3s ease;
            position: relative;
            text-decoration: none;

            &:after {
                content: '';
                transition: all 0.3s ease;
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                background-color: $fifth-color;
                bottom: -10px;
                transform: scale(0);

            }
            &:hover {
                color: $fifth-color;
            }
            &.active {
                color: $fifth-color;
                &:after {
                    transform: scale(1);
                }
            }
            &.active {
                color: $fifth-color;
                -webkit-text-stroke: 1px;
                letter-spacing: 2px;
            }
            &.login {
                margin-left: auto;
                a {
                    display: flex;
                    align-items: center;
                    margin-top: 0;
                    margin-bottom: 0;
                    text-decoration: none;
                    color: $main-light-color;
                    transition: color 0.5s ease;
                    > svg {
                        margin-right: 5px;
                    }
                    &:hover {
                        color: $fifth-color;
                    }
                }
            }
            &.back-btn {
                display: flex;
                align-items: center;
            }
        }

        .download-button {
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 10px;
            align-self: flex-end;
            margin: 0 0 0 auto;
        }
    }
}
$main-color: #2b3039;
$main-light-color: #8b97ac;
$secondary-color: #a3b5d5;
$third-color: #384255;
$fourth-color: #ebe8ff;
$fifth-color: #ffffff;
$sixth-color: #f0f0f0;
$seventh-color:rgb(197, 197, 197);
$logo-color: #204761;
$accept-color: #4ba759;
$orange-color: #ff9c53;
$blue-color: #89A7D9;

$extra-color: #0e2f38;
$extra-color-2: #233a3e;
$extra-color-3: #3c5053;

$secondary-color: $blue-color;

$font-color-main: $main-color;
$font-color-secondary: $fifth-color;

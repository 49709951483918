#specifications {
    > div {
        width: calc(100vw - 100px);
        max-width: 978px;
        padding: 50px;
    }
    > div.hero {
        max-width: initial !important;
        min-height: calc(50vh);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $main-color;
        padding-top: 100px;
        img {
            margin-top: auto;
            display: block;
            width: 200px;
            filter: brightness(5);
        }
        h1 {
            color: $fifth-color;
            margin-top: 50px;
            letter-spacing: 25px;
        }
        h3 {
            color: $main-light-color;
            font-weight: normal;
            letter-spacing: 3px;
            font-size: 2rem;
        }
        p {
            color: $main-light-color;
            font-size: 1.5rem;
            margin-top: auto;
        }
    }
    .fh > ul {
        margin-top: 100px;
        list-style: none;
        > li {
            margin-top: 25px;
            margin: 15px;
            color: $main-color;
            padding-bottom: 3px;
            border-bottom: 5px solid $main-color;
            width: fit-content;
            transition: all 0.5s ease;
            &:not(.submenu) {
                cursor: pointer;
            }
            &:not(.submenu):hover {
                color: $main-light-color;
                border-color: $main-light-color;
            }
            &.submenu {
                border-bottom: 0;
            }
            ul {
                list-style: none;
                li {
                    font-size: 1.2rem;
                    margin: 10px;
                    padding-bottom: 3px;
                    border-bottom: 1px solid $main-color;
                    cursor: pointer;
                    transition: all 0.5s ease;
                    &:hover {
                        color: $main-light-color;
                        border-color: $main-light-color;
                    }
                }
            }
        }
    }
    .list-title {
        font-size: 1.5rem;
        font-weight: bold;
    }
    .text-right {
        padding-right: 10px;
    }
    table {
        border-spacing: 10px;
        width: 100%;
        thead {
            tr {
                th {
                    vertical-align: middle;
                    padding: 10px;
                    background-color: $blue-color;
                    width: 200px;
                    max-width: 200px;
                    min-width: 200px;
                    &.number {
                        width: 50px;
                        max-width: 50px;
                        min-width: 50px;
                        text-align: center;
                    }
                }
            }
        }
        tbody {
            tr {
                &.title > td {
                    background-color: #d1d1d1;
                    text-align: center;
                    font-size: 18px;
                    letter-spacing: 1px;
                }
                td {
                    background-color: $sixth-color;
                    padding: 10px;
                    vertical-align: middle;
                    > svg {
                        vertical-align: middle;
                    }
                    &:first-child {
                        font-weight: bold;
                    }
                    &.number {
                        text-align: center;
                    }
                    &.bold span {
                        font-weight: bold;
                    }
                    .sub-price {
                        font-weight: normal !important;
                        font-style: italic;
                        font-size: .85rem;
                    }
                    .require {
                        &:after {
                            content: '*';
                            color: red;
                        }
                    }
                    li {
                        margin: 0.5rem 0;
                        word-break: keep-all;
                        &.italic {
                            font-size: .85rem;
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }
}
@import '../colors.scss';

#price {
    position: relative;
    overflow: hidden;
    padding: 25px 0 50px;
    margin-bottom: 25px;
    background-color: $extra-color;
    justify-content: flex-start;
    min-height: auto;
    h3 {
        color: $fifth-color;
        font-weight: 300;
    }
    .title {
        max-width: 1280px;
        width: 100%;
        margin: 50px auto !important;
        display: block;
    }
    > h3:not(.caption) {
        letter-spacing: 1px;
        max-width: 1280px;
        width: 100%;
        margin-top: -30px;
        > span {
            font-weight: bold;
        }
        > a {
            text-decoration: none;
            color: white;
            font-weight: bold;
        }
    }
    h2 {
        text-transform: uppercase;
    }
    > .title {
        margin-right: auto;
        margin-left: 100px;
        margin-bottom: 50px;
        color: white;
        & + h3 {
            color: $fifth-color;
            margin-left: 100px;
            margin-right: auto;
            font-weight: 300;
            font-size: 2rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            > span {
                font-weight: 500;
                margin: 0 10px;
            }
            > a {
                height: auto;
                display: flex;
                margin: 0 0 0 10px;
                img {
                    width: 120px;
                }
            }
        }
    }
    .pc {
        display: flex;
        position: relative;
        margin-top: 50px;
        margin-bottom: 100px;
        margin-right: auto;
        margin-left: calc(50% - 680px);
        .additional {
            padding: 25px;
            border-radius: 5px;
            background-color: $extra-color-2;
            width: 320px;
            left: 910px;
            top: 200px;
            transition: all 0.5s ease;
            transform: translateY(-50%);
            position: absolute;
            text-align: center;
            &.opened {
                top: 330px;
            }
            h3 {
                color: white;
                font-weight: 400;
                font-size: 2rem;
                margin: 0;
                > span {
                    font-size: 1.5rem;
                }
            }
            p {
                color: darken($color: $fifth-color, $amount: 10%);
                font-size: 1.2rem;
                margin-top: 0;
            }
            a {
                text-decoration: none;
                display: inline-block;
                vertical-align: middle;
                img {
                    width: 80px;
                }
            }
        }
        .package {
            padding: 25px;
            border-radius: 5px;
            background-color: $extra-color-3;
            position: relative;
            display: block;
            min-width: 750px;
            max-width: 750px;
            margin-left: 100px;
            margin-right: auto;
            .line {
                margin: 15px 0;
            }
            .pack {
                .pack-title {
                    display: flex;
                    align-items: center;
                    h2 {
                        margin: 0;
                        margin-left: 10px;
                        color: $fifth-color;
                        letter-spacing: 5px;
                        > span {
                            padding-left: 5px;
                        }
                    }
                    img {
                        display: block;
                        width: 50px;
                        filter: grayscale(1) brightness(0.2);
                    }
                }
                .pack-price {
                    h3 {
                        text-transform: none;
                        letter-spacing: 1px;
                        font-size: 4rem !important;
                        font-weight: 400 !important;
                        margin: 25px 0;
                        & > span {
                            font-weight: 300 !important;
                            font-size: 2rem;
                            margin-left: 15px;
                        }
                    }
                }
                .pack-details {
                    ul {
                        list-style: none;
                        padding: 0 0 0 25px;
                        margin: 25px 0;
                        li {
                            color: $fifth-color;
                            display: flex;
                            align-items: center;
                            font-size: 1.2rem;
                            margin-bottom: 15px;
                            svg {
                                font-size: 2.5rem;
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .min-pack {
        p {
            color: $fifth-color !important;
            font-weight: bold;
            letter-spacing: 1px;
        }
        h3 {
            color: white;
            font-weight: 400;
            font-size: 2rem;
            margin: 0;
            > span {
                font-size: 1.5rem;
                margin-left: 5px;
                font-weight: 300;
            }
        }
    }
    .more-info {
        background-color: $extra-color-2;
        margin: 0 -25px;
        transition: all 0.5s ease;
        max-height: 0;
        padding: 0 25px;
        overflow: hidden;
        &.open {
            padding: 25px;
            max-height: 1000px;
        }
        h3 {
            color: white;
            font-weight: 400;
            font-size: 2rem;
            margin: 0;
            > span {
                font-size: 1.5rem;
                margin-left: 5px;
                font-weight: 300;
            }
        }
        p {
            color: darken($color: $fifth-color, $amount: 10%);
            font-size: 1.2rem;
            margin-top: 0;
        }
        ul {
            list-style: none;
            padding: 0 0 0 25px;
            margin: 25px 0;
            li {
                color: $fifth-color;
                display: flex;
                align-items: center;
                font-size: 1rem;
                margin-bottom: 15px;
                svg {
                    font-size: 2rem;
                    margin-right: 15px;
                }
            }
        }
    }
    .more-panel {
        &.opened {
            .btn-group {
                margin-top: 25px;
            }
        }
    }
    .btn-group {
        display: flex;
        align-items: center;

        > div {
            cursor: pointer;
            margin-top: 0;
            font-size: 13px;
            letter-spacing: 1px;
        }
        > button {
            margin-left: auto;
            margin-top: 0;
            > a {
                text-decoration: none;
                color: $third-color;
            }
        }
    }
}
@import '../colors.scss';

.boxes {
    transform-style: preserve-3d;
    &:nth-child(1) {
        transform: translateZ(0);
        position: relative;
        > .box {
            background-color: $secondary-color;
        }
    }
    &:nth-child(2) {
        transform: scale(1.2) translateZ(10px);
        position: relative;
        z-index: 2;
        > .box {
            background-color: $main-light-color;
        }
    }
    &:nth-child(3) {
        transform: translateZ(5px);
        position: relative;
        > .box {
            background-color: $third-color;
        }
    }
    .box {
        border-radius: 5px;
        width: calc(100vw / 3 - 200px);
        height: calc(100vw / 3 - 200px);
        display: flex;
        flex-direction: column;
        padding: 15px;
        margin: 15px;
        color: $fifth-color;
        transform-style: preserve-3d;
        transform: rotateY(0);
        transition: all 0.5s cubic-bezier(.28,.35,.51,.91);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 10px 20px -15px black;
        position: relative;
        * {
            pointer-events: none;
        }
        & > p {
            font-size: 4rem;
            font-weight: bold;
            margin-bottom: 15px;
            margin-top: 15px;
        }
        h3 {
            text-align: center;
            margin-top: 50px;
            letter-spacing: 2px;
        }
        b {
            color: $main-color;
            text-align: center;
            font-size: 0.8rem;
        }
    }
}
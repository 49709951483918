@import '../colors.scss';

.panels {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .panel {
        display: flex;
        flex-wrap: wrap;
        color: $fifth-color;
        margin: 25px 0;
        max-width: 1280px;
        > h1 {
            margin-bottom: 50px !important;
        }
        > h1, > h3 {
            width: 100%;
            padding-left: 25px;
        }
        .panel-details {
            padding: 0 25px;
            width: calc(60vw - 100px);
            p {
                font-size: 1.5rem;
                margin-top: 0;
                a {
                    font-weight: bold;
                    text-decoration: none;
                    color: $secondary-color;
                    transition: all 0.5s ease;
                    &:after {
                        transition: all 0.5s ease;
                        position: relative;
                        content: '';
                        display: block;
                        margin-top: 3px;
                        width: 165px;
                        height: 3px;
                        background-color: $main-light-color;
                        transform: scale(0);
                    }
                    &:hover {
                        color: $main-light-color;
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
            }
            > h2 {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 0;
                font-size: 1.8rem;
                > img {
                    background-color: transparent;
                    border: 0;
                    width: 100px;
                    height: auto;
                    box-shadow: none;
                    margin-left: 10px;
                    margin-right: 10px;
                    padding-top: 3px;
                }
                & + h2 {
                    margin-top: 50px;
                }
            }
            ul {
                padding-left: 50px;
                margin-top: 25px;
                margin-bottom: 50px;
                > li {
                    line-height: 1.5;
                }
            }
        }
        > img {
            width: 40vw !important;
            height: 40vw !important;
            object-fit: cover;
        }
        h4 {
            font-size: 1.4rem;
        }
        h2 {
            letter-spacing: 2px;
        }
    }
}
@keyframes grow {
    0% {
        opacity: 1;
        transform: scale(0);
    } 100% {
        opacity: 0;
        transform: scale(1);
    }
}

#branches {
    background-color: $extra-color;
    min-height: auto;
    padding-bottom: 25px;
    h3 {
        padding-left: 25px;
    }
    .branches-container {
        .main-q {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
            .ring {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                & .f-1 {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    z-index: 1;
                    background-color: $fifth-color;
                    animation-name: grow;
                    animation-duration: 5s;
                    animation-delay: 0;
                    animation-timing-function: ease-out;
                    animation-iteration-count: infinite;
                }
                & .f-2 {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    z-index: 2;
                    background-color: $fifth-color;
                    animation-name: grow;
                    animation-duration: 5s;
                    animation-delay: .5s;
                    animation-timing-function: ease-out;
                    animation-iteration-count: infinite;
                }
                & .f-3 {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    z-index: 3;
                    background-color: $fifth-color;
                    animation-name: grow;
                    animation-duration: 5s;
                    animation-delay: 1s;
                    animation-timing-function: ease-out;
                    animation-iteration-count: infinite;
                }
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    z-index: 4;
                    background-color: $fifth-color;
                }
                .q {
                    background-color: $fifth-color;
                    position: relative;
                    z-index: 5;
                    font-size: 25px !important;
                    line-height: .8;
                    color: $blue-color;
                    border: 3px solid $blue-color;
                    border-radius: 50%;
                    padding: 10px;
                    font-weight: bold;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                }
            }
            p {
                margin-left: 1rem;
                color: $fifth-color;
            }
        }
        .branches-box {
            > .flex-box {
                flex-wrap: wrap;
                justify-content: center;
                max-width: 1100px;
                max-height: 2000px;
                overflow: hidden;
                transition: all 0.5s ease;
                &.toggled {
                    max-height: 225px;
                }
                > div {
                    width: calc(100% / 3 - 2rem);
                    height: auto;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    margin: 1rem;
                    min-width: 300px;
                    .img-ic {
                        padding: 15px;
                        background-color: $blue-color;
                        border-radius: 50%;
                        width: 50px;
                        display: block;
                        > img {
                            display: block;
                            width: 100%;
                        }
                    }
                    p {
                        color: $fifth-color;
                        margin-left: 1rem;
                    }
                }
            }
            > button {
                margin: 3rem auto;
                display: block;
            }
        }
    }
}
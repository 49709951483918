@media screen and (max-width: 1200px) {
    #services .container .text .title {
        font-size: 3vw;
    }
}

@media screen and (max-width: 978px) {
    body > #root > div {
        h1,
        h1 > span {
            font-size: 1.8rem !important;
            text-align: center;
            letter-spacing: 1px;
        }
        h2 {
            font-size: 1.4rem;
            letter-spacing: 1px;
        }
        h3,
        h3 > span {
            font-size: 1.2rem !important;
        }
        p, a, button, li, span {
            font-size: 16px !important;
        }
        header {
            flex-direction: column;
            background-color: $main-color;
            transition: all 0.5s ease;
            height: 20px;
            padding: 15px;
            width: calc(100vw - 30px);
            overflow: hidden;
            &.mobile-open {
                height: calc(100vh - 30px);
            }
            > a {
                margin-bottom: auto !important;
                margin-right: auto !important;
                margin-left: 0 !important;
                margin-top: -5px !important;
                min-height: 30px;
            }
            > nav {
                transition: all 0.5s ease;
                margin-top: 15px;
            }
            ul {
                flex-direction: column;
                height: calc(100vh - 115px);
                > li {
                    margin: 10px 0 0 0 !important;
                    font-size: 24px !important;
                    text-align: center;
                    border-bottom: 1px solid $main-light-color;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    &:first-child {
                        margin-top: auto !important;
                    }
                    &:not(.btn) {
                        padding: 0 10px 10px 10px;
                    }
                    &.active {
                        border-bottom: 2px solid white;
                        &:after {
                            display: none;
                        }
                    }
                    &.login {
                        border-bottom: 0;
                        > a {
                            margin: 0;
                            font-size: 24px !important;
                        }
                    }
                    &.btn {
                        margin-bottom: auto !important;
                        padding: 10px;
                    }
                }
            }
        }
        footer {
            flex-direction: column;
        }
        section {
            padding: 15px;
            box-sizing: border-box;
            .title {
                margin: auto;
                font-size: 1.5rem !important;
                margin-bottom: 25px !important;
                margin-top: 25px !important;
                .border-bottom {
                    border-bottom: 3px solid $blue-color;
                    padding-left: 1px;
                }
            }
            .caption {
                font-size: 14px !important;
                margin-bottom: 0 !important;
                margin-top: 25px !important;
            }
            &:not(:first-of-type) {
                padding-top: 0;
                padding-bottom: 0;
            }
            &#hero {
                padding-top: 75px;
                #main-logo,
                #logo {
                    max-width: 90px;
                }
                .btn-grouping {
                    margin-bottom: 15px;
                }
                .bg > .react-player {
                    iframe {
                        transform: scale(3);
                    }
                }
                .g,
                .socials {
                    left: 0;
                    position: relative;
                    top: 0;
                    width: 100%;
                }
                .socials {
                    width: auto;
                    margin-top: 15px;
                }
                > h1 {
                    margin-top: 25px;
                }
            }
            &#slogan {
                padding-top: 0;
                > button {
                    margin-top: 0;
                }
                .title {
                    font-size: 1.5rem !important;
                    text-align: center;
                    margin-top: 0 !important;
                    top: 50px;
                }
                > button {
                    padding-left: 35px;
                    top: 100px;
                    font-size: 14px !important;
                }
                > h2 {
                    font-size: 1.2rem;
                    top: 80px;
                }
                .container {
                    max-height: 700px;
                    &:before,
                    &:after {
                        height: 50vh;
                    }
                    &:after {
                        position: absolute;
                        min-height: 88vh;
                        height: 100%;
                        top: 50vh;
                    }
                    #ice {
                        > h3 {
                            width: 100%;
                        }
                        ul {
                            width: 100%;
                            transform: translateX(-50%);
                            bottom: 17vh;
                            > li {
                                font-size: 12px !important;
                                margin: 15px 0;
                            }
                        }
                    }
                }
                img {
                    &#boat {
                        top: -50.4vw;
                        height:auto;
                        width: 40vw;
                        position: relative;
                        left: 10%;
                    }
                }
            }
            &#why .panels > img {
                position: relative;
                width: calc(100% - 30px);
                left: 0;
                bottom: 0;
            }
            &#about .panels > .panel:first-child {
                border-bottom: 2px solid;
            }
            &#about, &#why {
                .panels {
                    > .panel {
                        flex-direction: column;
                        width: 100vw;
                        position: relative;
                        margin: 0;
                        &:not(:first-child) {
                            h1 {
                                margin-top: 35px;
                            }
                        }
                        > h1,
                        > h1 > span {
                            font-size: 1.2rem !important;
                            padding: 0;
                            position: relative;
                            z-index: 1;
                            margin-bottom: 15px !important;
                            &.border-bottom {
                                border: 0;
                            }
                        }
                        h3, h2 {
                            font-size: 1rem;
                            padding: 0;
                            text-align: left;
                            position: relative;
                            z-index: 1;
                            margin-top: 15px;
                            > img {
                                width: auto;
                                height: 15px;
                            }
                        }
                        > h3 {
                            padding: 15px;
                            box-sizing: border-box;
                        }
                        .panel-details {
                            width: 100%;
                            padding: 0 15px;
                            position: relative;
                            z-index: 1;
                            h2 > img {
                                top: 0;
                                & + span {
                                    margin-left: 10px;
                                }
                            }
                            ul {
                                padding: 0 0 25px 0;
                                margin-top: 0;
                                margin-bottom: 35px;
                                li {
                                    margin: 5px;
                                    svg {
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                        > img {
                            display: none;
                        }
                    }
                }
            }
            &#why, &#about {
                .title {
                    padding: 0 15px !important;
                    > .border-bottom {
                        font-size: 1.5rem !important;
                    }
                }
            }
            &#price {
                padding-top: 25px;
                .title > .border-bottom {
                    font-size: 1.5rem !important;
                }
                .pc {
                    flex-direction: column;
                    margin-left: auto;
                    padding-bottom: 25px;
                    margin-top: 25px;
                    .package {
                        min-width: initial;
                        width: calc(100vw - 60px);
                        padding: 15px;
                        margin: 0;
                        .pack-title {
                            h2 {
                                font-size: 1.2rem;
                                margin: 0;
                                text-align: center;
                                > span {
                                    border: 0;
                                    font-size: 1.2rem !important;
                                    padding: 0;
                                }
                            }
                        }
                        .pack-price {
                            h1 {
                                font-size: 1.8rem !important;
                                > span {
                                    font-size: 1rem !important;
                                }
                            }
                        }
                        .more-info {
                            width: 100%;
                            margin: 0 -15px;
                            padding: 0 15px;
                            &.open {
                                padding: 15px;
                            }
                        }
                        .more-panel {
                            > .btn-group {
                                flex-direction: column;
                                > .btn,
                                > button {
                                    width: 100%;
                                    box-sizing: border-box;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                    .additional {
                        width: auto;
                        left: 0;
                        top: 0;
                        transition: all 0.5s ease;
                        transform: translateY(0);
                        position: relative;
                        margin-top: 25px;
                        .plus {
                            margin-top: -72px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                > h1 {
                    margin: 0;
                }
                > h3 {
                    margin: 15px 0 0 0;
                    justify-content: flex-start;
                    text-align: left;
                    > a {
                        > img {
                            height: 23px;
                            width: auto;
                        }
                    } 
                }
            }
            &#calendar {
                justify-content: flex-start;
                height: auto;
                h1 {
                    margin-top: 30px;
                }
                .calendar {
                    min-height: initial;
                    width: 100vw;
                    padding: 0;
                    margin: -25px;
                    .caption,
                    .title {
                        padding-left: 15px;
                    }
                    .calendly-inline-widget {
                        overflow: hidden !important;
                        min-width: initial !important;
                        height: 1065px !important;
                        transform: scale(1) !important;
                    }
                    > p {
                        box-sizing: border-box;
                        padding: 15px;
                    }
                }
            }
            &#branches {
                .branches-container {
                    .main-q {
                        margin-top: 2rem;
                    }
                    .branches-box {
                        .branch {
                            width: 100%;
                        }
                    }
                }
            }

            &#services {
                padding: 90px 0;

                .container {
                    flex-direction: column-reverse;
                    padding-bottom: 0;
        
                    .text {
                        width: 100%;

                        .btn {
                            width: auto;
                        }

                        .note {
                            font-size: 12px !important;

                            a {
                                font-size: 12px !important;
                            }
                        }
                    }

                    .image {
                        width: 100%;
                        margin-bottom: 40px;
                    }
                }
            }
        }
        .btn-grouping {
            flex-direction: column;
            > div,
            > a,
            > button {
                width: 100%;
            }
        }
        .menu-bar {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 30px;
            display: flex;
            height: 30px;
            align-items: center;
            transition: all 0.5s ease;
            flex-direction: column;
            transform: rotate(0);

            span {
                width: 30px;
                min-height: 5px;
                background-color: $main-light-color;
                margin: 3px 5px;
                display: block;
                transition: all 0.5s ease;
                opacity: 1;
                transform: scale(1);
            }
            &.open {
                transform: rotate(-135deg);
                > span {
                   position: absolute;
                   &.line-1 {
                       transform: rotate(90deg);
                   }
                   &.line-2 {
                       transform: scale(0);
                       opacity: 0;
                       pointer-events: none;
                   }
                }
            }
        }
        .go-up.visible {
            right: 15px;
            bottom: 15px;
            width: 24px;
            height: 25px;
            padding: 10px;
        }
        #agb,
        #privacy {
            h1 {
                font-size: 1.1rem !important;
            }
        }
        #specifications {
            > div {
                width: 100%;
                &.hero {
                    > img {
                        width: 200px;
                    }
                    > h1 {
                        letter-spacing: 10px;
                    }
                }
                &.fh {
                    padding: 25px;
                    > ul {
                        margin-top: 0;
                        padding: 0;
                        > li {
                            font-size: 18px !important;
                        }
                    }
                }
                &#system {
                    padding: 25px;
                }
            }
            table {
                width: 100%;
                overflow-x: auto;
                display: block;
            }
        }
        footer {
            .links + span {
                text-align: center;
            }
        }
    }
    #impressum,
    #agb,
    #privacy {
        padding-top: 50px !important;
    }
}
@media all and (max-width: 978px) and (orientation: landscape) {
    body > #root > div {
        section {
            &#slogan {
                #ice {
                    top: 5vw;
                }
                #boot {
                    top: -128.4vw;
                }
            }
        }
    }
}
#agb,
#impressum,
#privacy {
    padding: 0 50px;
    max-width: 978px;
    margin: auto;
    align-items: flex-start;
    h1 {
        font-size: 2rem;
        color: $main-color !important;
    }
    h2 {
        font-size: 1.8rem;
        color: $main-color !important;
    }
    h3 {
        font-size: 1.2rem;
        color: $main-color !important;
    }
    p {
        font-size: 16px;
        color: $main-light-color;
    }
    p > span {
        margin-right: 5px;
        color: darken($color: $main-light-color, $amount: 10%);
    }
}
#agb,
#privacy {
    padding: 150px 50px;
}
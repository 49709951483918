@import '../colors.scss';
@mixin button($color, $background-color, $border-color) {
    background-color: $background-color;
    color: $color;
    border: 1px solid $border-color;
    &:hover {
        background-color: darken($color: $background-color, $amount: 10%);
    }
}
button, a.btn, div.btn, li.btn {
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    border: 0;
    transition: all 0.3s ease;
    font-weight: bold;
    margin-top: 30px;
    font-size: 16px !important;

    &.btn-primary {
        @include button($main-color, $secondary-color, transparent);
    }
    &.btn-secondary {
        @include button($secondary-color, $main-color, $secondary-color);
        &.active {
            @include button($main-color, $secondary-color, transparent);
        }
    }
    &.btn-tertiary {
        @include button($secondary-color, transparent, $secondary-color);
        &.active {
            @include button($main-color, $secondary-color, transparent);
        }
    }
    &.btn-accept {
        @include button($fifth-color, $accept-color, transparent);
        padding: 20px 10px;
        box-shadow: -5px 3px 10px -10px black;
        font-size: 18px;
    }
}
.btn-grouping {
    display: flex;
    margin-top: 25px;
      & > button {
          margin: 5px;
      }
}
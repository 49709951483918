
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;900&display=swap');
@import './colors.scss';
@import './responsive.scss';
@import './wigets/buttons.scss';
@import './wigets/panels.scss';
@import './wigets/boxes.scss';
@import './components/hero.scss';
@import './components/navigation.scss';
@import './components/about.scss';
@import './components/price.scss';
@import './components/calendar.scss';
@import './components/specifications.scss';
@import './components/agb.scss';
@import './components/slogan.scss';
@import './components/branches.scss';
@import './components/services.scss';

@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300;
    .caption {
        color: $seventh-color !important;
        letter-spacing: 1px !important;
        margin: 50px auto !important;
        max-width: 1280px;
        width: 100%;
        text-transform: uppercase;
        font-weight: bold !important;
    }
    .bold {
        font-weight: bold;
    }
    .center {
        margin: auto;
    }
    .background {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100vw;
        height: 100vh;
        display: block;
        object-fit: contain;
        overflow: hidden;
    }
}
* {
    font-family: 'Roboto', sans-serif !important;

    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $font-color-secondary;
    }

    ::-webkit-scrollbar {
        width: 12px;
        background-color: $font-color-secondary;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        width: 10px;
        background-color: $main-color;
    }
}
section {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}
h1,
.title {
    font-size: 3em;
    margin-bottom: 0;
    margin-top: 15px;
    letter-spacing: 5px;
    color: $main-color;
    font-weight: 900 !important;
}
.title {
    margin-bottom: 50px !important;
}
h2 {
    font-size: 2.5rem;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 0;
    font-weight: bold;
}
p {
    font-weight: 300;
}
.next-section {
    padding: 10px;
    background-color: $logo-color;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: block;
    fill: white;
    position: absolute;
    bottom: 25px;
    cursor: pointer;
    path {
        pointer-events: none;
    }
}
.flex-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    & > div {
        width: 50%;
        height: calc(100vh - 100px);
        padding: 50px 100px;
    }
    &.column {
        flex-direction: column;
        align-items: center;
    }
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    flex-direction: column;
    position: fixed;
    background-color: $fifth-color;
    transition: all 0.5s ease;
    transform: translateY(0);

    img {
        width: 250px;
        height: 250px;
        position: relative;
        top: -43px;
        left: -7px;
    }
    h3 {
        font-weight: 300;
        font-size: 2rem;
        color: $secondary-color;
    }
    &.hide {
        transform: translateY(-100vh);
        z-index: -1;
    }
}
.hide-scrollbar {
    height: 100vh;
    overflow: hidden;
}
.line {
    max-width: 1250px;
    width: 100%;
    height: 3px;
    background-color: $fifth-color;
    margin: 50px 0;
    display: block;
}
.additional {
    max-width: 1250px;
    .title {
        margin-left: 0 !important;
        margin-top: 50px;
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            color: $fifth-color;
            display: flex;
            align-items: center;
            font-size: 2rem;
            padding-left: 50px;
            svg {
                font-size: 3rem;
                fill: $secondary-color;
                margin-right: 10px;
            }
        }
    }
}
.slogan {
    width: 100vw;
    height: 100vh;
    background-color: $fifth-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $main-color;
    &:before, &:after {
        position: relative;
        content: '';
        display: block;
        width: 100vw;
        height: 51vh;
        z-index: 0;
    }
    &:before {
        background-color: #7b9db9;
        height: 53.99vh;
    }
    &:after {
        background-color: $extra-color;
    }
    .title {
        color: $main-color;
        position: absolute;
        top: 100px;
        left: 50px;
        z-index: 2;
        color: white;
    }
    h2 {
        text-align: center;
        font-weight: 300;
        max-width: 1200px;
        position: relative;
        z-index: 2;
        color: white;
    }
    i {
        text-align: right;
        max-width: 1300px;
        margin-right: 100px;
        font-size: 1.2rem;
        width: 100%;
        position: relative;
        z-index: 2;
        color: white;
    }
}
.go-down {
    position: absolute;
    bottom: 50px;
    right: 50px;
    display: block;
    background-color: $secondary-color;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
    font-size: 2rem !important;
}
.go-up {
    position: fixed;
    bottom: -100px;
    z-index: 999;
    right: 50px;
    display: block;
    background-color: $secondary-color;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
    font-size: 2rem !important;
    transition: all 0.5s ease !important;
    &.visible {
        bottom: 50px;
    }
}
.plus {
    width: 70px;
    height: 70px;
    position: absolute;
    border-radius: 50%;
    background-color: $fifth-color;
    display: flex;
    left: -45px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 15px -10px black;
    margin-top: 45px;
    > span {
        width: 30px;
        height: 5px;
        background-color: $extra-color;
        display: block;
        position: absolute;
        &:first-child {
            transform: rotate(90deg);
        }
    }
}
footer {
    padding: 100px 10px;
    background-color: $extra-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 20px);
    .logos {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        img {
            width: 60px;
            margin-bottom: 5px;
            filter: brightness(2);
            animation-name: rotate;
            animation-duration: 20s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: normal;
            transform: rotate(0);
        }
        p {
            font-size: 14px;
            color: $fifth-color;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 2px;
        }
    }
    a {
        color: $fifth-color;
        text-decoration: none;
        transition: all 0.5s ease;
        &:hover {
            color: $fifth-color;
        }
        &:not(:first-child) {
            margin-left: 15px;
        }
    }
    span {
        color: $fifth-color;
        opacity: 0.6;
        margin-top: 50px;
        font-size: 12px;
        letter-spacing: 1px;
    }
}
.border-bottom {
    border-bottom: 5px solid $blue-color;
    padding-left: 3px;
}
.check {
    width: 20px;
    margin-right: 10px;
    display: block;
    animation-name: rotate;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    transform: rotate(0);
}
.text-red {
    color: red;
}
.text-right {
    text-align: right;
}
.text-bold {
    font-weight: bold;
}